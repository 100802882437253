<template>
<v-card>
  <v-container>
    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
      <v-form  @submit.prevent="passes(save)">
        <v-card-text>
          <v-text-field
            :label="$store.state.ajustes.label_medico | capitalize"
            :value="medico.nombre"
            dense
            readonly
            hide-details
            class="mb-8"
          ></v-text-field>

          <v-text-field
            label="Consultorio"
            :value="consultorio?.descripcion"
            dense
            readonly
            hide-details
            class="mb-8"
          ></v-text-field>

          <v-text-field
            label="Fecha"
            :value="fecha"
            dense
            readonly
            hide-details
            class="mb-8"
          ></v-text-field>

          <v-text-field
            label="Hora"
            :value="turno.start"
            dense
            readonly
            hide-details
            class="mb-8"
          ></v-text-field>

          <ValidationProvider vid="motivo_id" name="Motivo" rules="required" v-slot="{ errors, valid }">
            <v-select
              v-model="turno.motivo_id"
              :items="motivos"
              item-value="id"
              item-text="nombre"
              required
              dense
              label="Motivo"
              :error-messages="errors"
              :success="valid"
            ></v-select>
          </ValidationProvider>
        </v-card-text>

        <v-card-actions>
          <v-btn type="submit"
            color="primary" dark
            :loading="saving">reservar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text :disabled="saving" @click.stop="close">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-container>
</v-card>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"
import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  name: 'TurnoCreateForm',

  props: {
    medicos: [],
    turno: {},
  },

  data: () => ({
    saving: false,
    errors: [],
    consultorios: [],
    tiposDocumento: [],
    obrasSociales: [],
    motivos: [],
  }),

  computed: {
    medico () {
      return this.medicos.find((medico) => (medico.id == this.turno.medico_id))
    },

    consultorio () {
      return this.consultorios.find((consultorio) => (consultorio.id == this.turno.consultorio_id))
    },

    fecha () {
      return moment(this.turno.date).format('DD/MM/YYYY')
    },
  },

  methods: {
    save () {
      this.saving = true

      let turno = {
        medico_id: this.turno.medico_id,
        consultorio_id: this.turno.consultorio_id,
        inicio: this.turno.date + ' ' + this.turno.start + ':00',
        fin: this.turno.date + ' ' + this.turno.end + ':00',
        motivo_id: this.turno.motivo_id,
      }

      this.$http.post('turnos', turno)
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', 'Se reservó el turno', 'primary')
          this.close(response.data)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit(
                'snackbar-message',
                error?.response?.data?.message || 'Hay errores en el formulario',
                'error')

              this.errors = error?.response?.data?.errors || []

              break;
            default:
              this.$eventHub.$emit(
                'snackbar-message',
                error?.response?.data?.message || 'Disculpe, ocurrío un error al procesar su solicitud',
                'error'
              )
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    fetchConsultorios () {
      this.$http.get('consultorios')
      .then((response) => {
        this.consultorios = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    fetchMotivos () {
      this.$http.get('motivos')
      .then((response) => {
        this.motivos = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    close: function (item = false) {
      this.$emit('cancel', item)
    },
  },

  created () {
    this.fetchConsultorios()
    this.fetchMotivos()
  },
}
</script>
