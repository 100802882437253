<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet
        tile
        height="54"
        color="grey lighten-3"
        class="d-flex justify-space-between align-center"
      >
        <v-btn
          :plain="!$vuetify.breakpoint.mobile"
          :icon="$vuetify.breakpoint.mobile"
          :disabled="!canGoBack"
          @click="$refs.calendar.prev()"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
          <span v-if="!$vuetify.breakpoint.mobile">Semana Anterior</span>
        </v-btn>

        <v-select
          v-model="medico"
          :items="medicos"
          :loading="isLoadingMedicos"
          item-value="id"
          item-text="nombre"
          return-object
          dense
          outlined
          hide-details
          :label="$store.state.ajustes.label_medico | capitalize"
          @change="changeMedico"
        ></v-select>

        <v-btn
          :plain="!$vuetify.breakpoint.mobile"
          :icon="$vuetify.breakpoint.mobile"
          :disabled="!canGoNext"
          @click="$refs.calendar.next()"
        >
          <span v-if="!$vuetify.breakpoint.mobile">Próxima Semana</span>
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>

        <v-dialog v-model="dialog" persistent max-width="290">
          <TurnoCreateForm
            :medicos="medicos"
            :turno="turno"
            @cancel="close"
          />
        </v-dialog>
      </v-sheet>

      <v-sheet>
        <v-progress-linear
          indeterminate
          color="primary"
          :active="loading"
        ></v-progress-linear>

        <v-calendar
          id="calendar"
          ref="calendar"
          color="primary"
          :now="today"
          :value="today"
          :events="events"
          :event-color="getEventColor"
          :type="$vuetify.breakpoint.mobile ? 'day' : 'week'"

          :interval-minutes="intervalMinutes"
          :first-interval="intervalFirst"
          :interval-count="intervalCount"

          :weekdays="weekdays"
          v-model="focus"

          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        >
          <template v-slot:day-header="{ weekday, date, past }">
            <v-row v-if="!past && !diasNoLaborables.includes(date)" class="fill-height">
              <v-col class="caption text-center horarios" title="Horarios">
                {{ horarios[weekday] ? horarios[weekday].horarios : '' }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:event="{ event }">
            <div class="d-flex justify-center align-center fill-height">
              <span class="text-h6">{{ event.hora_ini }}</span>
            </div>
          </template>
        </v-calendar>

        <v-menu
          v-model="selectedOpen"
          :close-on-click="!confirmDeleteDialog"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              color="blue"
              dark
              flat
            >
              <v-toolbar-title>{{ selectedEvent.dia }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon
                aria-label="Cerrar"
                title="Cerrar"
                @click="selectedOpen = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-title>{{ selectedEvent.hora_ini }}</v-card-title>

            <v-card-text>
              <div>
                <span class="text-capitalize">{{ $store.state.ajustes.label_medico }}</span>:
                <span v-html="selectedEvent.medico"></span>
              </div>
              <div>Consultorio: <span v-html="selectedEvent.consultorio"></span></div>
              <div>Motivo: <span v-html="selectedEvent.motivo_nombre"></span></div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                text
                color="primary"
                @click="onDeleteTurno()"
              >
                Cancelar Turno
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="selectedOpen = false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <v-card
          v-if="intervalCount === 0"
          color="grey lighten-4"
          elevation="0"
          tile
        >
          <v-card-text class="text-center body-1">
            {{ $vuetify.breakpoint.mobile ? 'Este día' : 'Esta semana' }} no tiene turnos disponibles
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>

    <v-dialog
      v-model="confirmDeleteDialog"
      max-width="100%"
      width="400"
      :persistent="sending"
      :style="{ zIndex: 200 }"
      @keydown.esc="confirmDeleteDialog = false"
    >
      <v-card>
        <v-card-title style="word-break: break-word;">¿Cancelar el turno?</v-card-title>
        <v-card-text v-if="parseInt(medico?.limite_cancelacion || 0)">
          Solo se puede cancelar con <span class="font-weight-bold">{{ medico.limite_cancelacion }} {{ parseInt(medico.limite_cancelacion) !== 1 ? 'horas' : 'hora' }}</span> de antelación.
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            :disabled="sending"
            @click="confirmDeleteDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            :loading="sending"
            @click="deleteTurno"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import moment from 'moment'
import 'moment/locale/es';

import TurnoCreateForm from '../components/TurnoCreateForm'

export default {
  components: {
    TurnoCreateForm,
  },

  name: 'Turnos',

  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    events: [],

    weekdays: [1, 2, 3, 4, 5],

    minDate: null,
    maxDate: null,

    date: null,
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    turno: {},
    turnoDefault: {
      id: undefined,
      name: null,
      details: null,
      date: '',
      start: null,
      end: null,
      color: 'blue',
    },

    dialog: false,
    confirmDeleteDialog: false,

    intervalMinutes: 60,
    intervalFirst: 0,
    intervalCount: 24,

    limiteReserva: null,

    medicos: [],

    loading: true,
    sending: false,
    isLoadingMedicos: true,

    medico: null,

    isLoadingHorarios: true,
    horarios: [],

    isLoadingDiasNoLaborables: true,
    diasNoLaborables: [],
  }),

  computed: {
    canGoBack() {
      return moment(this.minDate).isAfter(moment(), 'day')
    },

    canGoNext() {
      return moment(this.maxDate).isBefore(this.limiteReserva, 'day')
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
  },

  methods: {
    viewDay ({ date }) {
      this.focus = date
    },

    getEventColor (event) {
      if (event.tomado_por) {
        return 'blue'
      }

      return 'green'
    },

    setToday () {
      this.focus = this.today
    },

    prev () {
      this.$refs.calendar.prev()
    },

    next () {
      this.$refs.calendar.next()
    },

    showEvent ({ nativeEvent, event }) {
      nativeEvent.stopPropagation()

      if (event.id) {
        // Mostrar datos del turno tomado
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        return
      }

      // Mostrar formulario para tomar el turno
      this.turno = {...this.turnoDefault}

      this.turno.date = event.dia
      this.turno.start = event.hora_ini
      this.turno.end = event.hora_fin
      this.turno.medico_id = this.medico.id
      this.turno.consultorio_id = this.medico.consultorio_id

      this.dialog = true
    },

    updateRange ({ start, end }) {
      this.minDate = start.date
      this.maxDate = end.date

      if (!this.isLoadingHorarios) {
        this.query();
      }
    },

    query () {
      if (!this.medico) return;

      this.loading = true

      let filters = {
        medico_id: this.medico.id,
        date_from: this.minDate,
        date_to: this.maxDate,
      };

      return this.$http.get('turnos', {params: filters})
      .then((response) => {
        this.armarCalendario(response.data)
        this.armarEncabezadoCalendario()
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .then(() => {
        this.loading = false;
      })
    },

    close: function (item = false) {
      if (item) {
        // TODO: Actualizar eventos solamente?
        this.query()
      }

      this.dialog = false
    },

    onDeleteTurno() {
      this.confirmDeleteDialog = true
    },

    deleteTurno () {
      this.sending = true

      this.$http.delete('turnos/'+this.selectedEvent.id)
        .then(() => {
          this.events = []
          this.query();
          this.selectedOpen = false
        })
        .catch((error) => {
          this.$eventHub.$emit(
            'snackbar-message',
            error?.response?.data?.message || 'No se pudo procesar su solicitud',
            'error'
          )
        })
        .finally(() => {
          this.sending = false
          this.confirmDeleteDialog = false
        })
    },

    fetchMedicos () {
      this.isLoadingMedicos = true

      return this.$http.get('medicos')
      .then((response) => {
        this.medicos = response.data

        if (this.medicos.length) {
          this.medico = this.medicos[0]
          this.changeMedico()
        } else {
          this.$eventHub.$emit(
            'snackbar-message',
            `No hay registros para el filtro ${this.$store.state.ajustes.label_medico}`,
            'error'
          )
          this.loading = false
        }
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .then(() => {
        this.isLoadingMedicos = false
      })
    },

    changeMedico () {
      this.limiteReserva = moment().add(this.medico.limite_reserva, 'days')
      this.intervalMinutes = parseInt(this.medico.turno_intervalo)
      this.intervalCount = 1440 / this.intervalMinutes
      this.fetchHorarios(this.medico.id).then(() => {
        this.query()
      })
    },

    fetchDiasNoLaborables () {
      this.isLoadingDiasNoLaborables = true

      return this.$http.get('dias-no-laborables', {
        params: {
          desde: this.today,
        }
      })
      .then((response) => {
        this.diasNoLaborables = response.data.map(({ fecha }) => fecha)
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .then(() => {
        this.isLoadingDiasNoLaborables = false
      })
    },

    fetchHorarios (medico_id) {
      this.isLoadingHorarios = true

      return this.$http.get(`medicos/${medico_id}/horarios?group=weekday`)
      .then((response) => {
        this.horarios = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .then(() => {
        this.isLoadingHorarios = false
      })
    },

    armarCalendario (tomados) {
      this.events = []

      // Variables para establecer la franja horaria para el calendario
      let startTime = '99:99';
      let endTime = '00:00';
      let dayIndex = -1;

      // Bucle por cada día entre las fechas que se muestran
      for (
        var dia = moment(this.minDate);
        dia.diff(moment(this.maxDate), 'days') <= 0;
        dia.add(1, 'days')
      ) {
        // Aumentar el indice
        dayIndex++

        // Ignorar fecha anteriores
        if (dia.isBefore(moment(), 'day')) {
          continue
        }

        // Ignorar fecha posteriores al límite
        if (dia.isAfter(this.limiteReserva, 'day')) {
          continue
        }

        // Establecer el "día" formateado
        const diaString = dia.format('YYYY-MM-DD')

        // Ignorar dias no laborables
        if (this.diasNoLaborables.includes(diaString)) {
          document.querySelectorAll('#calendar .v-calendar-daily_head-day')[dayIndex].classList.add('is-holiday')
          document.querySelectorAll('#calendar .v-calendar-daily__day')[dayIndex].classList.add('is-holiday')
          continue
        }

        // Bucle cada día de la semana en el horario
        const weekday = dia.day()

        // Controlar que el dia de la semana tenga horarios
        if (!this.horarios[weekday]) {
          continue
        }

        const horarios = this.horarios[weekday].horarios.split('\r\n')

        // Bucle por cada horario
        for (var index = horarios.length - 1; index >= 0; index--) {
          const rango = horarios[index].split(' - ')

          const iniHorario = rango[0]
          const finHorario = rango[1]

          // Actualizar la franja horaria
          if (iniHorario < startTime) {
            startTime = iniHorario
          }

          if (finHorario > endTime) {
            endTime = finHorario
          }

          // Bucle por cada bloque en el rango horario
          var start = moment(diaString + ' ' + iniHorario, 'YYYY-MM-DD HH:mm')
          var finish = moment(diaString + ' ' + finHorario, 'YYYY-MM-DD HH:mm')

          for (
            var hora = moment(start);
            hora.diff(finish, 'minutes') < 0;
            hora.add(this.intervalMinutes, 'minutes')
          ) {
            if (hora.isBefore(moment(), 'minute')) {
              // No crear evento si es del pasado
              continue
            }

            // Buscar si existe el turno para la hora de inicio
            const turno = tomados.find((elem) => {
              return (elem.inicio === hora.format('YYYY-MM-DD HH:mm:ss'))
            })

            if (turno && (turno.tomado_por === 'TERCERO')) {
              // No crear evento si ya esta tomado por otra persona
              continue
            }

            if (turno) {
              this.events.push({
                name: 'TOMADO',
                start: turno.inicio,
                end: turno.fin,
                id: turno.id,
                tomado_por: turno.tomado_por,
                medico_id: turno.medico_id,
                medico: turno.medico_nombre,
                consultorio_id: turno.consultorio_id,
                consultorio: turno.consultorio_descripcion,
                motivo_nombre: turno.motivo_nombre,
                dia: turno.inicio_dia_fmt,
                hora_ini: turno.inicio_hora_fmt,
                hora_fin: turno.fin_hora_fmt,
              });

              continue
            }

            // El turno esta libre
            const end = moment(hora).add(this.intervalMinutes, 'm')

            this.events.push({
              name: 'LIBRE',
              start: hora.format('YYYY-MM-DD HH:mm'),
              end: end.format('YYYY-MM-DD HH:mm'),
              id: null,
              tomado_por: null,
              medico_id: null,
              medico: null,
              consultorio_id: null,
              consultorio: null,
              dia: hora.format('YYYY-MM-DD'),
              hora_ini: hora.format('HH:mm'),
              hora_fin: end.format('HH:mm'),
            });
          } // end for "bloques"
        } // end for "horarios"
      } // end for "dia"

      // No se armaron turnos para la semana
      if (startTime === '99:99') {
        this.intervalCount = 0

        return
      }

      // Actualizar franja horaria e intervalos
      const startHour = moment(startTime, "HH:mm")
      const startOfDay = moment().startOf('day');

      this.intervalFirst = Math.floor(startHour.diff(startOfDay, 'minutes') / this.intervalMinutes)

      const endHour = moment(endTime, "HH:mm")

      const intervalLast = Math.ceil(endHour.diff(startOfDay, 'minutes') / this.intervalMinutes)

      this.intervalCount = intervalLast - this.intervalFirst
    },

    armarEncabezadoCalendario () {
      var minDate = moment(this.minDate); 
      var maxDate = moment(this.maxDate); 

      var contentElements = document.querySelectorAll('.v-calendar-daily_head-day-label .v-btn__content');
      
      var currentDate = minDate.clone();
      var index = 0;

      contentElements.forEach(element => {
        var formattedDate = currentDate.format('MMM D');

        element.textContent = formattedDate;
        currentDate.add(1, 'day');
        index++;

        if (currentDate.isAfter(maxDate) || index >= contentElements.length) {
          return false;
        }
      });
    }
  },

  created () {
    this.fetchDiasNoLaborables().then(() => {
      this.fetchMedicos()
    })
  },

  mounted () {
    this.turno = this.turnoDefault
  },
}
</script>

<style scoped>
  .horarios {
    white-space: pre-line;
  }

  >>> .v-calendar-daily__scroll-area {
    overflow-y: hidden !important;
  }

  >>> .v-calendar .v-event-timed-container {
    margin-right: 0;
  }

  >>> .v-calendar-daily_head-day.v-past,
  >>> .v-calendar-daily__day.v-past {
    background-color: #EEEEEE;
  }

  >>> .v-calendar-daily_head-day.is-holiday,
  >>> .v-calendar-daily__day.is-holiday {
    background-color: #BDBDBD;
  }

  >>> .v-calendar-daily_head-day.is-holiday .v-calendar-daily_head-day-label > button > span {
    text-decoration: line-through;
  }

  >>> .v-calendar-daily__day .v-event-timed-container .v-event-timed.green:hover {
    background-color: #1B5E20 !important;
  }

  >>> .v-calendar-daily__day .v-event-timed-container .v-event-timed.blue:hover {
    background-color: #0D47A1 !important;
  }
</style>
